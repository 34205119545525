import React, { useState } from "react"
import CountUp from "react-countup"
import { Helmet } from "react-helmet"
import VisibilitySensor from "react-visibility-sensor"
import Layout from "../components/layout"
import AboutUSRoundTwo from "../images/AboutUSRightRoundTwo.png"
import bg2 from "../images/IC.svg"
import bg5 from "../images/accountability.svg"
import Shapefour from "../images/bannerElement/white-design-element-four.svg"
import Shapeone from "../images/bannerElement/white-design-element-one.svg"
import Shapethree from "../images/bannerElement/white-design-element-three.svg"
import Shapetwo from "../images/bannerElement/white-design-element-two.svg"
import bg1 from "../images/clientfocus.svg"
import bg4 from "../images/heart.svg"
import TeamP2 from "../images/hotelsandresortslefts.jpg"
import ISO from "../images/logos/Certifications/ISO_27001.png"
import SOC from "../images/logos/Certifications/SOC_NonCPA.png"
import bg3 from "../images/team.svg"
import "../styles/404.css"
import "../styles/about-us.css"
import VideoBanner from "../video/leadership-team-banner-video.mp4"

const AboutUs = () => {
  const [countUpVisible, setCountUpVisible] = useState(false)
  const handleVisibilityChange = isVisible => {
    if (isVisible) {
      setCountUpVisible(true)
    }
  }
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    fade: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Who We Are | Position²</title>
        <script src="https://use.fortawesome.com/23bb216b.js"></script>
        <body className="home" />
      </Helmet>
      <Layout>
        <div class="about-us content-creative">
          <section id="Banner">
            <div class="bgVideo">
              <video
                width="100%"
                playsInline
                muted
                autoPlay
                loop
                className="video-js BiggerScreen"
                data-setup="{'controls': false, 'autoplay': true, 'preload': true, 'loop':true }"
              >
                <source src={VideoBanner} type="video/mp4" />
              </video>
            </div>
            <div className="bannerContent">
              <div class="container">
                <div class="banner-holder">
                  <div class="images">
                    <div className="img-one image-shape">
                      <img src={Shapeone} alt="Image" />
                    </div>
                    <div className="img-two image-shape">
                      <img src={Shapetwo} alt="Image" />
                    </div>
                    <div className="img-three image-shape">
                      <img src={Shapethree} alt="Image" />
                    </div>
                    <div className="img-four image-shape">
                      <img src={Shapefour} alt="Image" />
                    </div>
                  </div>
                  <div class="title">
                    <div class="banner-title">
                      <h1>
                        We are Position<sup>2</sup>
                      </h1>
                      <p>
                        Where AI meets growth - Your partner <br />
                        in success
                      </p>
                      <a className="button" href="/contact-us/">
                        Get in touch
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="PageIntro">
            <div class="container">
              <h2>Pioneering the future of growth marketing</h2>
              <p>
                We are Position<sup>2</sup>, a passionate team dedicated to
                helping businesses unlock their true potential through the power
                of innovation and AI-driven growth marketing. With over two
                decades of experience, we have established ourselves as a leader
                in the industry, empowering brands to scale their success and
                achieve remarkable results.
              </p>
            </div>
          </section>
          <section id="TextImageSection">
            <div class="container">
              <div class="text-image-holder">
                <div class="text-wrap">
                  <h2>Innovators are changing the world</h2>
                  <p>
                    We believe that innovators are the catalyst for change in
                    the world. Our purpose is to empower these visionaries by
                    scaling their businesses profitably through cutting-edge
                    growth marketing strategies and AI-driven solutions.
                  </p>
                  <h2>
                    Our purpose is to scale them profitably with growth
                    marketing.
                  </h2>
                </div>
                <div class="img-wrap">
                  <div className="img-wrap-animation">
                    <img src={TeamP2} alt="One" />
                    <img src={TeamP2} alt="two" />
                    <img src={TeamP2} alt="two" />
                  </div>
                </div>
              </div>
              <ul className="countUp">
                <li>
                  <VisibilitySensor onChange={handleVisibilityChange}>
                    <div className="count-wrap">
                      <CountUp end={countUpVisible ? 20 : 0} duration={3} />
                      <span className="plus">+</span>
                    </div>
                  </VisibilitySensor>
                  <p>years of experience</p>
                </li>
                <li>
                  <VisibilitySensor onChange={handleVisibilityChange}>
                    <div className="count-wrap">
                      <CountUp end={countUpVisible ? 200 : 0} duration={3} />
                      <span className="plus">+</span>
                    </div>
                  </VisibilitySensor>
                  <p>dedicated team members</p>
                </li>
                <li>
                  <VisibilitySensor onChange={handleVisibilityChange}>
                    <div className="count-wrap">
                      <CountUp end={countUpVisible ? 300 : 0} duration={3} />
                      <span className="plus">+</span>
                    </div>
                  </VisibilitySensor>
                  <p>growing clients worldwide</p>
                </li>
                <li>
                  <VisibilitySensor onChange={handleVisibilityChange}>
                    <div className="count-wrap">
                      <CountUp end={countUpVisible ? 500 : 0} duration={3} />
                      <span className="plus">+</span>
                    </div>
                  </VisibilitySensor>
                  <p>successful projects delivered</p>
                </li>
              </ul>
            </div>
          </section>
          <section id="OurCoreValues">
            <div className="container">
              <div className="values-wrap">
                <div className="eachValues">
                  <h2>Our core values</h2>
                  <p>
                    Our client's success is our top priority and we take
                    responsibility to do what's right.
                  </p>
                </div>
                <div
                  className="eachValues"
                  style={{
                    backgroundImage: `url(${bg1})`,
                    backgroundPosition: "110% 110%",
                  }}
                >
                  <h2>
                    Client Focus{" "}
                    <span>
                      is at the heart <br />
                      of everything <br />
                      we do!
                    </span>
                  </h2>
                </div>
                <div
                  className="eachValues"
                  style={{
                    backgroundImage: `url(${bg2})`,
                    backgroundPosition: "85% 85%",
                  }}
                >
                  <h2>
                    Intellectual Curiosity <span>drives us forward!</span>
                  </h2>
                </div>
                <div
                  className="eachValues"
                  style={{
                    backgroundImage: `url(${bg3})`,
                    backgroundPosition: "105% 115%",
                  }}
                >
                  <h2>
                    Teamwork
                    <span>
                      {" "}
                      makes the dream work;
                      <br /> we’re stronger <br />
                      together!
                    </span>
                  </h2>
                </div>
                <div
                  className="eachValues"
                  style={{
                    backgroundImage: `url(${bg4})`,
                    backgroundPosition: "95% 95%",
                  }}
                >
                  <h2>
                    Integrity
                    <span>
                      {" "}
                      means doing the right thing <br /> when no one is looking!
                    </span>
                  </h2>
                </div>
                <div
                  className="eachValues"
                  style={{
                    backgroundImage: `url(${bg5})`,
                    backgroundPosition: "85% 90%",
                  }}
                >
                  <h2>
                    Accountability
                    <span>
                      {" "}
                      builds our culture <br />
                      and drives our growth!
                    </span>
                  </h2>
                </div>
              </div>
            </div>
          </section>
          <section id="TextImageSection" className="Joinourteam">
            <div class="container">
              <div class="text-image-holder">
                <div class="text-wrap">
                  <h2>Join our team of innovators and change-makers</h2>
                  <p>
                    Join the Position<sup>2</sup> team and be part of a dynamic,
                    fast-paced environment where you can make a real impact. We
                    offer exciting career opportunities across various domains,
                    including marketing, data science, and AI.
                  </p>
                  <a className="button" href="/careers/">
                    Join our team
                  </a>
                </div>
                <div class="img-wrap">
                  <img alt="Calibrate" src={AboutUSRoundTwo} />
                </div>
              </div>
            </div>
          </section>
          <section id="Certifications">
            <div class="container">
              <h2>Certifications</h2>
              <p>
                We are certified in ISO 27001:2022 and SOC 2 Type II,
                demonstrating a strong commitment to information security,
                privacy, and compliance.
              </p>
              <img alt="Certifications" src={ISO} />
              <img alt="Certifications" src={SOC} />
            </div>
          </section>
        </div>
      </Layout>
    </>
  )
}

export default AboutUs
